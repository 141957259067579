import React, { memo, useEffect, useState } from 'react';

import Filters from './Filters';
import Table from './Table';

import Pagination from '~/components/Pagination';

import api from '~/services/api';

function InternalTab() {
  const DEFAULT_FILTERS = {
    level: '',
    name: '',
  };

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState(null);

  const [filters, setFilters]: any = useState(DEFAULT_FILTERS);

  const doSearch = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    try {
      setLoading(true);

      const url = `dashboard/marketplace/categories/internal?page=${page}`;
      const res = await api.get(url, { params: filters });

      const { data } = res;

      data.links?.shift();
      data.links?.splice(data.links.length - 1, 1);

      setCategories(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    doSearch();
  }, [filters.level]);

  return (
    <>
      <header className="mt-3">
        <Filters
          filters={filters}
          setFilters={setFilters}
          onSave={() => doSearch()}
          onClear={() => {
            setFilters(DEFAULT_FILTERS);
          }}
        />
      </header>

      <main className="mb-3">
        <Table categories={categories} loading={loading} refresh={() => doSearch()} />
      </main>

      <footer className="d-flex justify-content-end mt-4">
        {categories && <Pagination onChange={(page) => doSearch(page)} {...categories} />}
      </footer>
    </>
  );
}

export default memo(InternalTab);
