import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';
import util from '~/assets/util';

const notification = {
  $s: function (text: string | HTMLElement, title: string | HTMLElement = undefined, modal = false) {
    if (modal) {
      this.notify(text, title, 'success', false, 'center');
    } else {
      this.notify(text, title, 'success');
    }
  },
  $e: function (error: any, title: string | HTMLElement = undefined) {
    let message = '';

    if (typeof error == 'string') {
      message = error;
    } else if (error.response) {
      const response = error.response;

      const data = response.data;

      if (response.status == 401) {
        message = util.t('TOKEN_HAS_EXPIRED');
      } else if (data.errors) {
        Object.values(data.errors).forEach((fieldErrors) => {
          Object.values(fieldErrors).forEach((errorMessage) => {
            message += `${util.t(errorMessage)} <br/>`;
          });
        });
      } else if (data.message) {
        message = util.t(data.message);
      }
    } else if (error == null) {
      message = util.t('GENERIC_ERROR');
    }

    this.notify(message, title, 'error');
  },

  $i: function (title: string | HTMLElement, text: string | HTMLElement, timer = 5000, showConfirmButton: boolean) {
    return this.notify(text, title, 'info', false, 'center', timer, showConfirmButton);
  },

  $w: function (message: string | HTMLElement, callback: any, title: string | HTMLElement = undefined) {
    return this.notify(title || util.t('WARNING'), message, 'warning', false, 'center', 0, true).then(callback);
  },

  notify: function (
    title: string | HTMLElement,
    text: string | HTMLElement,
    icon: SweetAlertIcon,
    toast = true,
    position: SweetAlertPosition = 'bottom-end',
    timer = 5000,
    showConfirmButton = false,
  ) {
    return Swal.fire({
      title,
      html: text,
      icon,
      toast,
      timer,
      position,
      showConfirmButton,
    });
  },
};

export default notification;
