import React, { memo, useState } from 'react';

import { UncontrolledTooltip } from 'reactstrap';

import { FaPen } from 'react-icons/fa';

import Modal from './Modal';

import Button from '~/components/Button';

import util from '~/assets/util';

interface ChangeOrderStatusButtonProps {
  className?: string;
  id?: any;
  storeOrder: any;
  onSave?: any;
}

function ChangeOrderStatusButton({
  className = 'mr-1',
  id = 'btn-change-order-status',
  storeOrder,
  onSave = null,
}: ChangeOrderStatusButtonProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button outline id={id} size="sm" color="primary" className={className} onClick={() => setShowModal(true)}>
        <FaPen />
      </Button>

      <UncontrolledTooltip target={id} placement="right">
        {util.t('CHANGE_STATUS')}
      </UncontrolledTooltip>

      <Modal isOpen={showModal} storeOrder={storeOrder} onSave={onSave} toggle={() => setShowModal(false)} />
    </>
  );
}

export default memo(ChangeOrderStatusButton);
