import React, { memo } from 'react';

import { Row, FormGroup, Input, Form } from 'reactstrap';

import Button from '~/components/Button';

import util from '~/assets/util';

interface FiltersProps {
  filters: any;
  setFilters: any;
  onSave: any;
  onClear: any;
}

function Filters({ filters, setFilters, onSave, onClear }: FiltersProps) {
  const doSubmit = (ev) => {
    ev.preventDefault();

    onSave();
  };

  const handleChange = (ev) => {
    const name = ev.target.name;

    setFilters({ ...filters, [name]: ev.target.value });
  };

  return (
    <Form className="container-fluid" onSubmit={doSubmit}>
      <Row className="justify-content-end">
        <div className="d-flex">
          <FormGroup className="mr-1">
            <Input id="filter_name" name="name" type="text" onChange={handleChange} value={filters.name} />
          </FormGroup>

          <FormGroup>
            <Button type="submit" color="primary">
              {util.t('SEARCH')}
            </Button>
            <Button className="ml-1" onClick={() => onClear()} type="button" color="secondary">
              {util.t('CLEAR')}
            </Button>
          </FormGroup>
        </div>
      </Row>
    </Form>
  );
}

export default memo(Filters);
