import React, { memo, useState } from 'react';

import { Spinner, Table as BTable } from 'reactstrap';

import Modal from './Modal';
import MarketplaceCategoryBadge from './MarketplaceCategoryBadge';

import util from '~/assets/util';

interface TableProps {
  categories: any;
  refresh: any;
  loading?: any;
}

function Table({ categories, refresh, loading = false }: TableProps) {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const openCategory = (category) => {
    setSelectedCategory(category);
  };

  const onSaveCategory = () => {
    setSelectedCategory(null);
    refresh();
  };

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={6}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (!categories?.data?.length) {
      return (
        <tr>
          <td className="text-center" colSpan={4}>
            <span>{util.t('NO_RECORDS_FOUND')}</span>
          </td>
        </tr>
      );
    }

    return categories?.data?.map((category) => (
      <tr key={category.category_id} className="cursor-pointer" onClick={() => openCategory(category)} role="button">
        <td>{category.name}</td>
        <td>
          <MarketplaceCategoryBadge category={category.marketplace_category} />
        </td>
        <td>{category.parent ? category.parent.name : '-'}</td>
      </tr>
    ));
  };

  return (
    <>
      <BTable hover responsive>
        <thead className="thead-light">
          <tr>
            <th>{util.t('NAME')}</th>
            <th>{util.t('MARKETPLACE_CATEGORY')}</th>
            <th>{util.t('ROOT_CATEGORY')}</th>
          </tr>
        </thead>

        <tbody>{renderBody()}</tbody>
      </BTable>

      <Modal
        category={selectedCategory}
        onSave={onSaveCategory}
        onClose={() => setSelectedCategory(null)}
        isOpen={selectedCategory != null}
      />
    </>
  );
}

export default memo(Table);
