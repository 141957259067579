import React, { memo } from 'react';

import { OrderStatus } from '~/assets/constants';
import util from '~/assets/util';

const statuses = Object.entries(OrderStatus)
  .filter(([key]) => isNaN(Number(key)))
  .map(([label, id]) => ({ label, id }));

function StatusSelect(props) {
  return (
    <select className="form-control" {...props}>
      <option value="">{util.t('ALL')}</option>
      {statuses.map((status) => {
        return (
          <option key={status.id} value={status.id}>
            {util.t(status.label)}
          </option>
        );
      })}
    </select>
  );
}

export default memo(StatusSelect);
