import React, { memo } from 'react';

import { Badge } from 'reactstrap';

import util from '~/assets/util';

function MarketplaceCategoryBadge({ category }: any) {
  const label = category ? category.full_name : util.t('PENDING');

  const color = category ? 'info' : 'danger';

  return (
    <span className="h5">
      <Badge color={color}>{label}</Badge>
    </span>
  );
}

export default memo(MarketplaceCategoryBadge);
