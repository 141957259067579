import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Outlet, useNavigate } from 'react-router-dom';

import Navbar from './Navbar';
import Sidebar from './Sidebar';

import './index.scss';

function Dashboard() {
  const user = useSelector((state: any) => state.user);

  const [collapsed, setCollapsed] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.data) {
      return navigate('/login');
    }
  }, []);

  return (
    <main id="wrapper" className={collapsed ? 'collapsed' : ''}>
      <Navbar {...{ collapsed, setCollapsed }} />
      <Sidebar {...{ collapsed, setCollapsed }} />

      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
