import axios, { AxiosRequestConfig } from 'axios';
import https from 'https';

const { NODE_ENV, REACT_APP_API_URL } = process.env;

const config: AxiosRequestConfig = {
  baseURL: REACT_APP_API_URL,
};

if (NODE_ENV == 'development') {
  config.httpsAgent = new https.Agent({ rejectUnauthorized: false });
}

const http = axios.create(config);

http.interceptors.request.use(
  function (config) {
    const authToken = localStorage.getItem('authtoken');

    if (authToken) {
      config.headers.common.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default http;
