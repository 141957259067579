import React, { memo, useEffect, useState } from 'react';

import { Row, Col, Input, Spinner, Table } from 'reactstrap';

import FormButtons from '~/components/FormButtons';
import StatusSwitch from '~/components/StatusSwitch';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import { ITenantProperties, ObjectFit, TenantClientType } from '~/types/tenant';

interface PropertiesFormProps {
  tenantId: any;
  onClose: any;
  onSave: any;
}

function PropertiesForm({ tenantId, onClose, onSave }: PropertiesFormProps) {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [properties, setProperties] = useState<ITenantProperties>({
    tenant_property_id: 0,
    banner_object_fit: ObjectFit.COVER,
    blackfriday_banner_url: '',
    coming_soon_url: '',
    coming_soon_art_url: '',
    coming_soon_background_color: '',
    follow_us_section: 0,
    free_shipping_bar: 0,
    searchbar: 0,
    headline_text: '',
    metaid_actionpay: '',
    partnerid_actionpay: '',
    type_client: TenantClientType.STORE,
    tenant_id: 0,
  });

  const handleChange = (ev) => {
    const name = ev.target.name;

    setProperties({ ...properties, [name]: ev.target.value });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      const form = {
        ...properties,
      };

      const url = `dashboard/tenants/${tenantId}/properties`;

      const res = await api.put(url, form);

      const data = res?.data;

      if (data) {
        onSave();
        onClose();

        notification.$s(util.t('SAVED_SUCCESSFULLY'));
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    async function loadProperties() {
      setLoading(true);

      try {
        const res = await api.get(`dashboard/tenants/${tenantId}/properties`);

        const { properties } = res?.data;

        if (properties) {
          setProperties({ ...properties });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    loadProperties();
  }, []);

  return (
    <form onSubmit={doSave} className="container-fluid p-3">
      {tenantId && loading ? (
        <div className="p-5">
          <Spinner size="lg" className="d-block m-auto" color="primary" />
        </div>
      ) : (
        <>
          <fieldset>
            <Table responsive>
              <thead>
                <tr>
                  <th>{util.t('PROPERTY')}</th>
                  <th>{util.t('VALUE')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{util.t('BANNER_OBJECT_FIT')}</td>
                  <td>
                    <Input type="select" name="banner_object_fit" value={properties.banner_object_fit || ''} onChange={handleChange}>
                      <option value="">Selecione</option>

                      <option value={ObjectFit.COVER}>{ObjectFit.COVER}</option>
                    </Input>
                  </td>
                </tr>
                <tr>
                  <td>{util.t('BLACKFRIDAY_BANNER_URL')}</td>
                  <td>
                    <Input
                      type="text"
                      name="blackfriday_banner_url"
                      value={properties.blackfriday_banner_url || ''}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{util.t('COMING_SOON_URL')}</td>
                  <td>
                    <Input type="text" name="coming_soon_url" value={properties.coming_soon_url || ''} onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>{util.t('COMING_SOON_ART_URL')}</td>
                  <td>
                    <Input type="text" name="coming_soon_art_url" value={properties.coming_soon_art_url || ''} onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>{util.t('COMING_SOON_BACKGROUND_COLOR')}</td>
                  <td>
                    <Input
                      type="text"
                      name="coming_soon_background_color"
                      value={properties.coming_soon_background_color || ''}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{util.t('FOLLOW_US_SECTION')}</td>
                  <td>
                    <StatusSwitch
                      checked={properties.follow_us_section === 1}
                      onChange={(checked) => setProperties({ ...properties, follow_us_section: checked ? 1 : 0 })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{util.t('FREE_SHIPPING_BAR')}</td>
                  <td>
                    <StatusSwitch
                      checked={properties.free_shipping_bar === 1}
                      onChange={(checked) => setProperties({ ...properties, free_shipping_bar: checked ? 1 : 0 })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{util.t('SEARCHBAR')}</td>
                  <td>
                    <StatusSwitch
                      checked={properties.searchbar === 1}
                      onChange={(checked) => setProperties({ ...properties, searchbar: checked ? 1 : 0 })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{util.t('HEADLINE_TEXT')}</td>
                  <td>
                    <Input type="text" name="headline_text" value={properties.headline_text || ''} onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>{util.t('METAID_ACTIONPAY')}</td>
                  <td>
                    <Input type="text" name="metaid_actionpay" value={properties.metaid_actionpay || ''} onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>{util.t('PARTNERID_ACTIONPAY')}</td>
                  <td>
                    <Input type="text" name="partnerid_actionpay" value={properties.partnerid_actionpay || ''} onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>{util.t('TYPE_CLIENT')}</td>
                  <td>
                    <Input type="select" name="type_client" value={properties.type_client} onChange={handleChange}>
                      <option value={TenantClientType.STORE}>{TenantClientType.STORE}</option>
                      <option value={TenantClientType.FRANCHISE}>{TenantClientType.FRANCHISE}</option>
                    </Input>
                  </td>
                </tr>
              </tbody>
            </Table>
          </fieldset>
          <hr />
          <Row>
            <Col>
              <footer className="d-flex justify-content-end">
                <FormButtons isEditing={false} isSaving={saving} onCancel={onClose} />
              </footer>
            </Col>
          </Row>
        </>
      )}
    </form>
  );
}

export default memo(PropertiesForm);
