import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

import Dashboard from './pages/Dashboard';
import ImportBestSellers from './pages/ImportBestSellers';
import Login from '~/pages/Login';
import MarketplaceCategories from './pages/MarketplaceCategories';
import Orders from './pages/Orders';
import Stores from './pages/Stores';
import StoreUsers from './pages/Stores/Users';
import Users from './pages/Users';

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<Dashboard />}>
          <Route index element={<Orders />} />
          <Route path="orders" element={<Orders />} />
          <Route path="stores" element={<Stores />} />
          <Route path="stores/:id/users" element={<StoreUsers />} />
          <Route path="users" element={<Users />} />
          <Route path="import-best-sellers" element={<ImportBestSellers />} />
          <Route path="marketplace-categories" element={<MarketplaceCategories />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
