import React from 'react';

import MaskedInput from 'react-text-mask';

interface InputPhoneProps {
  name?: string;
  value: any;
  onChange: any;
  id?: string;
  className: string;
  required?: boolean;
  placeholder?: string;
}

const InputPhone = ({ value, name, onChange, id, className, required = false, placeholder = '' }: InputPhoneProps) => {
  return (
    <MaskedInput
      {...{ onChange, value, name, id, className, required, placeholder }}
      type="text"
      mask={['(', /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
    />
  );
};

export default InputPhone;
