import React, { memo, useMemo, useState } from 'react';

import { FaSearchPlus, FaUsers } from 'react-icons/fa';
import { Button, Spinner, Table } from 'reactstrap';
import { format, parseISO } from 'date-fns';
import { NavLink } from 'react-router-dom';

import DeleteButton from '../DeleteButton';

import util from '~/assets/util';

import api from '~/services/api';
import notification from '~/services/notification';

import './index.scss';

interface StoresTableProps {
  stores: any[];
  loading?: any;
  onOpen: any;
  doUpdate: any;
}

function StoresTable({ stores, loading = false, onOpen, doUpdate }: StoresTableProps) {
  const [deleting, setDeleting] = useState([]);

  const doDelete = async (id) => {
    try {
      setDeleting(deleting.concat([id]));

      const url = `dashboard/tenants/delete/${id}`;

      const res = await api.delete(url);

      const data = res?.data;

      if (data) {
        notification.$s(util.t('STORE_DELETED'));

        doUpdate();
      }
    } catch (e) {
      notification.$e(e);
    } finally {
      setDeleting(deleting.filter((deletingId) => deletingId != id));
    }
  };

  const list = useMemo(
    () =>
      stores?.map((store) => (
        <tr key={store.tenant_id}>
          <td>{store.name}</td>
          <td>{store.slug}</td>
          <td>{store?.details?.cnpj}</td>
          <td>{util.t(store?.active ? 'ACTIVE' : 'INACTIVE')}</td>
          <td>{store.created_at && format(parseISO(store.created_at), 'dd/MM/yyyy')}</td>
          <td className="d-flex align-center justify-content-end">
            <Button onClick={() => onOpen(store.tenant_id)} size="sm" className="mr-1" outline color="info">
              <FaSearchPlus />
            </Button>

            <NavLink to={`/stores/${store.tenant_id}/users`}>
              <Button size="sm" className="mr-1" outline color="info">
                <FaUsers />
              </Button>
            </NavLink>

            <DeleteButton onDelete={() => doDelete(store.tenant_id)} size="sm" loading={deleting.indexOf(store.tenant_id) > -1} />
          </td>
        </tr>
      )),
    [stores, deleting],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={6}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (!stores?.length) {
      return (
        <tr>
          <td className="text-center" colSpan={5}>
            <span>{util.t('NO_RECORDS_FOUND')}</span>
          </td>
        </tr>
      );
    }

    return list;
  };

  return (
    <>
      <Table hover responsive>
        <thead className="thead-light">
          <tr>
            <th>{util.t('STORE')}</th>
            <th>{util.t('SLUG')}</th>
            <th>{util.t('LABEL_CNPJ')}</th>
            <th>{util.t('STATUS')}</th>
            <th>{util.t('DATE')}</th>
            <th className="text-right">{util.t('ACTION')}</th>
          </tr>
        </thead>

        <tbody>{renderBody()}</tbody>
      </Table>
    </>
  );
}

export default memo(StoresTable);
