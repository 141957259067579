import React, { memo } from 'react';

import { Spinner, Button as BootstrapButton } from 'reactstrap';
import util from '~/assets/util';

interface ButtonProps {
  children: any;
  loading?: any;
  spinnerColor?: string;
  spinnerSize?: string;
  [key: string]: any;
}

function Button({ children, loading = false, spinnerColor = 'white', spinnerSize = 'sm', ...props }: ButtonProps) {
  const renderButton = () => {
    if (loading) {
      return (
        <span>
          <Spinner color={spinnerColor} size={spinnerSize} /> {util.t('LOADING')}
        </span>
      );
    }

    return <>{children}</>;
  };

  return <BootstrapButton {...props}>{renderButton()}</BootstrapButton>;
}

export default memo(Button);
