import React, { useEffect, useState } from 'react';

import { Card, CardBody, CardHeader, Modal as BModal, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import GeneralForm from './GeneralForm';
import PropertiesForm from './PropertiesForm';

import util from '~/assets/util';

type TabType = 'general' | 'properties';

interface ModalProps {
  tenantId: any;
  onClose: any;
  onSave: any;
  onToggle: any;
}

const Modal = ({ tenantId, onClose, onSave, onToggle }: ModalProps) => {
  const [activeTab, setActiveTab] = useState<TabType>('general');

  useEffect(() => {
    setActiveTab('general');
  }, [tenantId]);

  return (
    <BModal autoFocus={false} isOpen={tenantId != null} toggle={onToggle} size="xl">
      <Card>
        <CardHeader>{`${util.t('STORE')}`}</CardHeader>
        <CardBody>
          {tenantId !== 0 && (
            <Nav tabs>
              <NavItem>
                <NavLink className={`cursor-pointer ${activeTab === 'general' ? 'active' : ''}`} onClick={() => setActiveTab('general')}>
                  {util.t('GENERAL_INFORMATION')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`cursor-pointer ${activeTab === 'properties' ? 'active' : ''}`}
                  onClick={() => setActiveTab('properties')}>
                  {util.t('PROPERTIES')}
                </NavLink>
              </NavItem>
            </Nav>
          )}

          <TabContent activeTab={activeTab}>
            <TabPane className="pt-2" tabId={'general'}>
              <GeneralForm tenantId={tenantId} onClose={onClose} onSave={onSave} />
            </TabPane>
            <TabPane className="pt-2" tabId={'properties'}>
              <PropertiesForm tenantId={tenantId} onClose={onClose} onSave={onSave} />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </BModal>
  );
};

export default Modal;
