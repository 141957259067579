import React, { memo, useEffect, useState } from 'react';

import { Card, CardBody, CardHeader, Modal as BModal, Form, FormGroup, Label } from 'reactstrap';

import MarketplaceCategorySelect from '~/components/MarketplaceCategorySelect';
import Button from '~/components/Button';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

interface ModalProps {
  onClose: any;
  onSave: any;
  category: any;
  isOpen?: any;
}

function Modal({ onClose, onSave, category, isOpen = false }: ModalProps) {
  const [saving, setSaving] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (category?.marketplace_category == null) {
      setSelectedOption(null);
      return;
    }

    const option = {
      value: category.marketplace_category.marketplace_category_id,
      label: category.marketplace_category.full_name,
    };

    setSelectedOption(option);
  }, [category]);

  const save = async (ev) => {
    ev.preventDefault();

    setSaving(true);

    try {
      await api.post('dashboard/marketplace/categories/bind', {
        category_id: category.category_id,
        marketplace_category_id: selectedOption?.value,
      });

      notification.$s(util.t('CATEGORY_SAVED'));

      onSave();
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  return (
    <BModal autoFocus={false} isOpen={isOpen} size="xl" toggle={onClose} unmountOnClose>
      <Card>
        <CardHeader>{category?.name}</CardHeader>
        <CardBody>
          <Form onSubmit={save}>
            <div className="row-form">
              <FormGroup>
                <Label for="marketplace-category">{util.t('MARKETPLACE_CATEGORY')}</Label>
                <MarketplaceCategorySelect
                  id="marketplace-category"
                  name="marketplace_category"
                  value={selectedOption}
                  onChange={setSelectedOption}
                />
              </FormGroup>
            </div>

            <Button color="primary" type="submit" loading={saving}>
              {util.t('SAVE')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </BModal>
  );
}

export default memo(Modal);
