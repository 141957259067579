import React, { memo, useState } from 'react';

import { FaHistory } from 'react-icons/fa';

import { Card, CardHeader, CardBody, Button, Modal, UncontrolledTooltip } from 'reactstrap';

import StatusHistoryTable from '../StatusHistoryTable';

import util from '~/assets/util';

function StatusHistoryButton({ logs }: any) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button outline id="status-history-button" size="sm" color="primary" onClick={() => setShowModal(true)}>
        <FaHistory />
      </Button>

      <UncontrolledTooltip target="status-history-button" placement="right">
        {util.t('CHANGES_HISTORY')}
      </UncontrolledTooltip>

      <Modal size="xl" isOpen={showModal} toggle={() => setShowModal(false)}>
        <Card>
          <CardHeader>{util.t('CHANGES_HISTORY')}</CardHeader>
          <CardBody>
            <StatusHistoryTable logs={logs} />
          </CardBody>
        </Card>
      </Modal>
    </>
  );
}

export default memo(StatusHistoryButton);
