import React, { memo } from 'react';

import { Row, FormGroup, Input, Label, Col, Form } from 'reactstrap';

import Button from '~/components/Button';
import InputCnpj from '~/components/InputCnpj';

import util from '~/assets/util';

interface FiltersProps {
  filters: any;
  setFilters: any;
  onSave: any;
  onClear: any;
}

function Filters({ filters, setFilters, onSave, onClear }: FiltersProps) {
  const doSubmit = (ev) => {
    ev.preventDefault();

    onSave();
  };

  const handleChange = (ev) => {
    const name = ev.target.name;

    setFilters({ ...filters, [name]: ev.target.value });
  };

  return (
    <Form onSubmit={doSubmit}>
      <Row form>
        <FormGroup className="col-sm-6 col-lg-5 col-xl-4">
          <Label htmlFor="filter_name">{util.t('STORE')}</Label>
          <Input id="filter_name" name="name" type="text" onChange={handleChange} value={filters.name} />
        </FormGroup>

        <FormGroup className="col-sm-6 col-lg-4 col-xl-4">
          <Label htmlFor="filter_cpf">{util.t('LABEL_CNPJ')}</Label>
          <InputCnpj className="form-control" id="filter_cnpj" name="cnpj" onChange={handleChange} value={filters.cnpj} />
        </FormGroup>
      </Row>

      <Row form>
        <Col>
          <Button type="submit" color="primary">
            {util.t('SEARCH')}
          </Button>
          <Button className="ml-1" onClick={() => onClear()} type="button" color="secondary">
            {util.t('CLEAR')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default memo(Filters);
