import I18n from '~/assets/i18n';

const util = {
  t(str: string) {
    return I18n.t(str);
  },

  format(number: number | string, decimals = 2, decPoint = ',', thousandsSep = '.') {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    const n = !isFinite(+number) ? 0 : +number;
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
    const dec = typeof decPoint === 'undefined' ? '.' : decPoint;
    const toFixedFix = function (n, prec) {
      const k = Math.pow(10, prec);
      return '' + (Math.round(n * k) / k).toFixed(prec);
    };

    const s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }

    if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
    }

    return s.join(dec);
  },

  getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    return `${year}-${month}-${day}`;
  },

  currencyFormatter(value: number) {
    if (!Number(value)) return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
      .format(value / 100)
      .replace(/^(\D+)/, 'R$ ');

    return `${amount}`;
  },

  formatYMDDate(date: string) {
    if (!date || !date.length) return '';

    return date.split('-').reverse().join('/');
  },
};

export default util;
