import React, { memo } from 'react';

import { Table } from 'reactstrap';

import { format, parseISO } from 'date-fns';

import util from '~/assets/util';

function WinbooksResume({ storeOrder }: any) {
  return (
    <Table bordered>
      <tbody>
        <tr>
          <th>{util.t('SENT_AT')}</th>
          <td>
            {storeOrder.winbooks_sent_at ? format(parseISO(storeOrder.winbooks_sent_at), 'dd/MM/yyyy HH:mm') : '-'}
            <div className="d-block"></div>
          </td>
        </tr>
        {storeOrder.winbooks_code && (
          <tr>
            <th>{util.t('CODE')}</th>
            <td>{storeOrder.winbooks_code}</td>
          </tr>
        )}
        {storeOrder.winbooks_id && (
          <tr>
            <th>ID</th>
            <td>{storeOrder.winbooks_id}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default memo(WinbooksResume);
