import React, { useEffect, useState } from 'react';

import { Button } from 'reactstrap';

import StoresTable from '~/components/StoresTable';
import Pagination from '~/components/Pagination';

import Filters from './Filters';
import Modal from './Modal';

import api from '~/services/api';

import util from '~/assets/util';

const DEFAULT_FILTERS = {
  name: '',
  cnpj: '',
};

function Stores() {
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState(null);

  const [filters, setFilters]: any = useState(DEFAULT_FILTERS);
  const [tenantId, setTenantId] = useState(null);

  const doSearch = async (page: any = 1) => {
    if (!parseInt(page)) {
      return;
    }

    try {
      setLoading(true);

      const res = await api.get(`tenants/all?page=${page}`, { params: filters });

      const { tenants } = res.data;

      tenants.links?.shift();
      tenants.links?.splice(tenants?.links.length - 1, 1);

      setStores(tenants.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const newTenant = () => {
    setTenantId(0);
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <header className="row mt-3">
        <div className="col-12">
          <div className="card p-3">
            <Filters
              filters={filters}
              setFilters={setFilters}
              onSave={doSearch}
              onClear={() => {
                setFilters(DEFAULT_FILTERS);
              }}
            />
          </div>
        </div>
      </header>

      <main className="card my-3">
        <section className="card-body">
          <div className="container-fluid p-3">
            <header>
              <h1 className="h2 m-0">{util.t('STORES')}</h1>
            </header>

            <div className="text-right">
              <Button onClick={newTenant} className="my-3" color="primary" outline>
                {util.t('ADD')}
              </Button>
            </div>

            <StoresTable
              onOpen={(id) => {
                setTenantId(id);
              }}
              loading={loading}
              stores={stores}
              doUpdate={doSearch}
            />

            <div className="d-flex justify-content-end mt-4">
              {stores && <Pagination onChange={(page) => doSearch(page)} {...stores} />}
            </div>
          </div>
        </section>

        <Modal
          onToggle={() => setTenantId(null)}
          tenantId={tenantId}
          onClose={() => {
            setTenantId(null);
          }}
          onSave={doSearch}
        />
      </main>
    </>
  );
}

export default Stores;
