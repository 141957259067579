import React, { memo, useEffect, useRef, useState } from 'react';

import { FormGroup, Row, Col, Label, Input, Collapse, Spinner } from 'reactstrap';

import InputCpf from '~/components/InputCpf';
import InputCellPhone from '~/components/InputCellPhone';
import InputBirthdate from '~/components/InputBirthdate';
import InputCnpj from '~/components/InputCnpj';
import InputIe from '~/components/InputIe';
import InputPhone from '~/components/InputPhone';
import FormButtons from '~/components/FormButtons';

import api from '~/services/api';
import notification from '~/services/notification';

import { PersonTypes, Roles } from '~/assets/constants';

import util from '~/assets/util';

interface FormProps {
  id: any;
  tenant_id: any;
  onClose: any;
  onSave: any;
}

function Form({ id, tenant_id, onClose, onSave }: FormProps) {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);

  const [user, setUser] = useState({
    user_id: '',
    person: PersonTypes.NATURAL_PERSON,
    password: '',
    email: '',
    name: '',
    cpf: '',
    cnpj: '',
    ie: '',
    phone: '',
    birthdate: '',
    cell_phone: '',
    company_name: '',
    gender: '',
    role: Roles.USER,
    tenant_id: tenant_id,
  });

  const inputNameRef = useRef(null);

  const isNaturalPerson = user.person == PersonTypes.NATURAL_PERSON;

  const handleChange = (ev) => {
    const name = ev.target.name;

    setUser({ ...user, [name]: ev.target.value });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      const form = {
        user: {
          ...user,
          birthdate: user.birthdate.split('/').reverse().join('-'),
        },
      };

      const url = `dashboard/users/${id ? 'update' : 'create'}`;

      const res = await api.post(url, form);

      const data = res?.data;

      if (data.user) {
        onSave();
        onClose();

        notification.$s(util.t('USER_SAVED'));
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const cancelEdit = () => {
    if (id === 0) {
      return onClose();
    }

    setEditing(true);
  };

  useEffect(() => {
    async function loadUser() {
      setLoading(true);

      try {
        const res = await api.get(`dashboard/users/${id}`);

        const data = res?.data;

        if (data.user) {
          const birthdate = util.formatYMDDate(data.user.birthdate);
          const user = { ...data.user, birthdate };

          setUser({ password: '', ...user });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      setEditing(true);

      loadUser();
    }
  }, []);

  useEffect(() => {
    !editing && inputNameRef?.current?.focus();
  }, [editing]);

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-3">
        {id && loading ? (
          <div className="p-5">
            <Spinner size="lg" className="d-block m-auto" color="primary" />
          </div>
        ) : (
          <>
            <fieldset disabled={editing}>
              <Row form>
                <Col md="6" lg="4">
                  <FormGroup>
                    <Label htmlFor="name">{util.t('NAME')}</Label>
                    <Input
                      className="form-control"
                      placeholder={util.t('FULL_NAME')}
                      value={user.name}
                      onChange={handleChange}
                      type="text"
                      maxLength={50}
                      name="name"
                      id="name"
                      required
                      autoFocus
                      innerRef={inputNameRef}
                    />
                  </FormGroup>
                </Col>

                <Col md="6" lg="4">
                  <FormGroup>
                    <Label htmlFor="email">{util.t('EMAIL')}</Label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      onChange={handleChange}
                      value={user.email}
                      required
                    />
                  </FormGroup>
                </Col>

                <Col md="6" lg="4">
                  <FormGroup>
                    <Label htmlFor="password">{util.t('PASSWORD')}</Label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      value={user.password}
                      onChange={handleChange}
                      autoComplete="off"
                      minLength={5}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <hr />

              <Row className="my-3" form>
                <Col>
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        name="personCheckbox"
                        type="radio"
                        value={PersonTypes.NATURAL_PERSON}
                        checked={isNaturalPerson}
                        onChange={() => setUser({ ...user, person: PersonTypes.NATURAL_PERSON })}
                      />
                      {util.t('NATURAL_PERSON')}
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        name="personCheckbox"
                        type="radio"
                        checked={!isNaturalPerson}
                        value={PersonTypes.JURIDICAL_PERSON}
                        onChange={() => setUser({ ...user, person: PersonTypes.JURIDICAL_PERSON })}
                      />
                      {util.t('JURIDICAL_PERSON')}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>

              <Collapse isOpen={isNaturalPerson}>
                <Row form>
                  <Col sm={4} lg={3}>
                    <FormGroup>
                      <Label htmlFor="cpf">{util.t('LABEL_CPF')}</Label>
                      <InputCpf
                        className="form-control"
                        value={user.cpf}
                        onChange={handleChange}
                        id="cpf"
                        name="cpf"
                        required={isNaturalPerson}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={4} lg={3}>
                    <FormGroup>
                      <Label htmlFor="gender">{util.t('GENDER')}</Label>
                      <select
                        required={isNaturalPerson}
                        className="form-control"
                        id="gender"
                        name="gender"
                        onChange={handleChange}
                        defaultValue={user.gender}>
                        <option disabled value="">
                          {util.t('SELECT')}
                        </option>
                        <option value="male">{util.t('MALE')}</option>
                        <option value="female">{util.t('FEMALE')}</option>
                      </select>
                    </FormGroup>
                  </Col>

                  <Col sm={4} lg={3}>
                    <FormGroup>
                      <Label htmlFor="birthdate">{util.t('BIRTHDATE')}</Label>
                      <InputBirthdate
                        className="form-control"
                        value={user.birthdate}
                        onChange={handleChange}
                        name="birthdate"
                        id="birthdate"
                        required={isNaturalPerson}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>

              <Collapse isOpen={!isNaturalPerson}>
                <Row form>
                  <Col sm={6} lg={3}>
                    <FormGroup>
                      <Label htmlFor="cnpj">{util.t('LABEL_CNPJ')}</Label>
                      <InputCnpj
                        value={user.cnpj || ''}
                        className="form-control"
                        onChange={handleChange}
                        name="cnpj"
                        id="cnpj"
                        required={!isNaturalPerson}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={6} lg={5}>
                    <FormGroup>
                      <Label htmlFor="companyName">{util.t('COMPANY_NAME')}</Label>
                      <input
                        required={!isNaturalPerson}
                        onChange={handleChange}
                        value={user.company_name || ''}
                        type="text"
                        className="form-control"
                        id="company_name"
                        name="company_name"
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={6} lg={3}>
                    <FormGroup>
                      <Label htmlFor="ie">{util.t('STATE_REGISTRY_NUMBER')}</Label>
                      <InputIe
                        value={user.ie || ''}
                        className="form-control"
                        onChange={handleChange}
                        name="ie"
                        id="ie"
                        required={!isNaturalPerson}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>

              <hr />

              <Row form>
                <Col sm={6} md={4} lg={3}>
                  <FormGroup>
                    <Label htmlFor="cell_phone">{util.t('CELL_PHONE')}</Label>
                    <InputCellPhone
                      className="form-control"
                      value={user.cell_phone || ''}
                      name="cell_phone"
                      id="cell_phone"
                      onChange={handleChange}
                      required={true}
                    />
                  </FormGroup>
                </Col>

                <Col sm={6} md={4} lg={3}>
                  <FormGroup>
                    <Label htmlFor="phone">{util.t('LANDLINE')}</Label>
                    <InputPhone onChange={handleChange} value={user.phone || ''} className="form-control" name="phone" id="phone" />
                  </FormGroup>
                </Col>

                <Col md={4} lg={3}>
                  <FormGroup>
                    <Label htmlFor="role">{util.t('USER_ROLE')}</Label>
                    <select value={user.role} onChange={handleChange} name="role" id="role" className="form-control">
                      <option value={Roles.USER}>{util.t('USER')}</option>
                      <option value={Roles.ADMIN}>{util.t('ADMIN')}</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
            </fieldset>
            <hr />
            <Row>
              <Col>
                <footer className="d-flex justify-content-end">
                  <FormButtons
                    isEditing={editing}
                    isSaving={saving}
                    onCancel={cancelEdit}
                    onEdit={() => {
                      setEditing(false);
                    }}
                  />
                </footer>
              </Col>
            </Row>
          </>
        )}
      </form>
    </>
  );
}

export default memo(Form);
