export enum ObjectFit {
  COVER = 'cover',
}

export enum TenantClientType {
  STORE = 'store',
  FRANCHISE = 'franchise',
}

export interface ITenantProperties {
  tenant_property_id: number;
  tenant_id: number;
  banner_object_fit: ObjectFit | null;
  blackfriday_banner_url: string | null;
  coming_soon_url: string | null;
  coming_soon_art_url: string | null;
  coming_soon_background_color: string | null;
  follow_us_section: number;
  free_shipping_bar: number;
  searchbar: number;
  headline_text: string | null;
  metaid_actionpay: string | null;
  partnerid_actionpay: string | null;
  type_client: TenantClientType;
}
