export enum Roles {
  USER = 'user',
  ADMIN = 'admin',
  DASHBOARD = 'dashboard',
  DASHBOARD_CATAVENTO = 'dashboard-catavento',
}

export enum PersonTypes {
  NATURAL_PERSON = 'natural',
  JURIDICAL_PERSON = 'juridical',
}

export enum PaymentMethods {
  PAYMENT_METHOD_BOLETO = 'boleto',
  PAYMENT_METHOD_CASH = 'cash',
  PAYMENT_METHOD_CREDIT_CARD = 'creditCard',
  PAYMENT_METHOD_CREDIT_CARD_MACHINE = 'creditCardMachine',
  PAYMENT_METHOD_ONLINE_DEBIT = 'eft',
  PAYMENT_METHOD_PIX = 'pix',
  PAYMENT_METHOD_VOUCHER = 'voucher',
}

export enum OrderStatus {
  STATUS_PENDING_PAYMENT = 0,
  STATUS_CONFIRMED_PAYMENT = 1,
  STATUS_PROCESSING = 2,
  STATUS_SHIPPED = 3,
  STATUS_COMPLETED = 4,
  STATUS_CANCELED = 5,
  STATUS_REFUNDED = 6,
  STATUS_READY_FOR_PICKUP = 99, // undefined
}

export enum CouponType {
  FREE_SHIPPING = 'shipping',
  PERCENT_DISCOUNT = 'percent',
}

export enum ListStates {
  AC = 'Acre',
  AL = 'Alagoas',
  AP = 'Amapá',
  AM = 'Amazonas',
  BA = 'Bahia',
  CE = 'Ceará',
  DF = 'Distrito Federal',
  ES = 'Espírito Santo',
  GO = 'Goiás',
  MA = 'Maranhão',
  MT = 'Mato Grosso',
  MS = 'Mato Grosso do Sul',
  MG = 'Minas Gerais',
  PA = 'Pará',
  PB = 'Paraíba',
  PR = 'Paraná',
  PE = 'Pernambuco',
  PI = 'Piauí',
  RJ = 'Rio de Janeiro',
  RN = 'Rio Grande do Norte',
  RS = 'Rio Grande do Sul',
  RO = 'Rondônia',
  RR = 'Roraima',
  SC = 'Santa Catarina',
  SP = 'São Paulo',
  SE = 'Sergipe',
  TO = 'Tocantins',
}
